<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditContratadoDialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <ContratadoDataTable ref="dataTable"
                           @click:edit="editar"
                           @click:remove="remover"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ContratadoDataTable from "../../components/shared/datatables/ContratadoDataTable";
import BaseView from "../BaseView";
import {findOne, remove} from "../../api/contratado";
import CreateEditContratadoDialog from "../../components/shared/dialogs/CreateEditContratadoDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions,mapGetters} from "vuex";
import {ApiError} from "../../api/core";

export default {
  name: "index",
  components: {CreateEditContratadoDialog, BaseView, ContratadoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Nome',
          column: 'nome',
          type: 'text'
        },
        {
          name: 'Razaão Social',
          column: 'razaoSocial',
          type: 'text'
        },
        {
          name: 'Tipo Pessoa',
          column: 'tipoPessoa',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_PESSOA
          })
        },        
        {
          name: 'CPF/CNPJ',
          column: 'cpfCnpj',
          type: 'text',
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    async editar(contratado) {
      const data = await this.getContratado(contratado.id);
      this.item = data;
      this.editDialogOpened = true;
    },

    async getContratado(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let contratado = page.dados[0];
          return contratado;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Contratado removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover o Contratado.`, {
            timeout: 6000
          })
        }
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Pessoa.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>