<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="800px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{ getTitle }}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">

        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <ContratadoForm v-model="item"/>
        </v-form>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContratadoForm from "../forms/ContratadoForm";
import {create, update} from "../../../api/contratado";
import {ApiError} from "../../../api/core";

export default {
  name: "CreateEditContratadoDialog",
  components: {ContratadoForm},
  props: ['value', 'item'],
  data() {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return this.item?.id ? 'Editar Contratado' : 'Adicionar Contratado'
    },
  },
  methods: {
    async save() {
      try {
        if (this.$refs.form.validate()) {
          if (this.item.id) {
            await update(this.item.id, this.item);
          } else {
            const {dados} = await create(this.item);
            this.$swal({
              title: 'Cadastrar novo Contrato?',
              html: `Contratado criado com sucesso.<br/>Deseja criar uma contrato para ele?<br/><strong>${dados.nome}</strong>`,
              icon: 'success',
              showCancelButton: true,
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim, Criar Contrato'
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: 'Contrato',
                  params: {
                    contratado: dados
                  }
                });
              }
            });
          }
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Contratado ${this.item.id ? 'atualizado' : 'criado'} com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'criar'} o Contratado'}.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>

</style>